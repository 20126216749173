* {
  margin: 0;
  padding: 0;
}
#root {
  background-color: #393e46;
  color: #ffffff;
}

#home, #about, #career,#skill, #projects {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}

@media (max-width: 991px) {
  #home, #about, #career,#skill, #projects {
    padding: 10rem 4% 2rem;
  }
}
.incolor {
  background: hsl(216, 18%, 16%);
  color: #ffffff;
  border: none;
}

.hb {
  color: #00adb5;
}

.h-btn:hover {
  background-color: #00adb5;
}
.h-link:hover {
  color: #00adb5 !important;
}

.album img {
  height: 300px;
  border-radius: 6px;
  box-shadow: 0px 0px 7px 0px #00adb5;
  padding: 0 !important;
  }
  .zoom-effect {
  padding: 0px;
  /* width: 100%; */
  /* height: auto; */
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.zoom-effect:hover {
  z-index: 999;
  transform: scale(1.1);
}

.banner-btn a {
  z-index: 1;
  transition: color 0.5s;
}

.banner-btn a span {
  width: 0;
  height: 100%;
  position: absolute;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: #5969ff;
  z-index: -1;
  transition: 0.5s;
}

.banner-btn a:hover span {
  width: 100%;
}

.banner-btn a:hover {
  /* color: black; */
  border: #5969ff;
}

.skills ul{
  border: 3px solid #00adb5;
  border-radius: 6px;
  padding: 16px 32px;
}
.skills ul li {
  list-style: none;
}
.skills ul h3 {
  font-size: 20px;
  line-height: 115%;
}

.skills ul div {
  position: relative;
  width: 100%;
  height: 25px;
  background: #D9D9D9;
}

.skills .boxs1 {
  width: 90%;
}

.skills .boxs2 {
  width: 85%;
}

.skills .boxs3 {
  width: 75%;
}

.skills .boxs4 {
  width: 85%;
}
.skills .boxs5 {
  width: 90%;
}

.skills .boxs6 {
  width: 80%;
}

.skills .boxs7 {
  width: 90%;
}

.skills .boxs8 {
  width: 90%;
}

.skills ul span {
  position: absolute;
  height: 100%;
  background: #00adb5;
}